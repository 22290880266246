import React, { useState } from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Burger from './burger';
import Overlay from './overlay'

const links = [
    // {
    //     path: '/',
    //     title: 'Home'
    // },
    {
        path: '/about-us',
        title: 'About Us'
    },
    {
        path: '/products',
        title: 'Products'
    },
    {
        path: '/seasonal-info',
        title: 'British Seasonal Info'
    },
    // {
    //     path: '/delivery-area',
    //     title: 'Delivery Area'
    // },
]

const Navbar = () => {
    const [overlayOpen, setOverlayOpen] = useState(false);
    const toggleOverlay = () => setOverlayOpen(prevState => !prevState);

    return (
        <header className="w-full antialiased bg-dark text-white px-8">
            <nav className='flex justify-between items-center w-full py-3 mx-auto max-w-7xl'>
                <Link to='/'>
                    <StaticImage
                        src="../../images/logo.png"
                        width={90}
                        // height='75px'
                        // width='75px'
                        // quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="Field to Fork Logo"
                    />
                </Link>
                <Burger toggle={toggleOverlay} />
                <ul className='hidden md:flex justify-around items-center max-w-5xl m-auto text-center md:space-x-9 lg:space-x-12 font-semibold leading-10'>
                    {links.map(({ path, title }) => (
                        <li>
                            <Link to={path}>{title}</Link>
                        </li>
                    ))}
                    {/* <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to='/products'>Products</Link>
                    </li>
                    <li>
                        <Link to='/seasonal-info'>British Seasonal Info</Link>
                    </li> */}
                </ul>
                <Link
                    to="/contact-us"
                    className="hidden md:flex px-10 py-2 text-lg font-medium text-center text-white bg-primary shadow-xl hover:bg-green-700 rounded-2xl"
                >
                    Contact us
                </Link>
            </nav>
            {overlayOpen && <Overlay toggle={toggleOverlay} />}
        </header>
    )
}

export default Navbar
