import React from 'react'
import PropTypes from "prop-types"
import { MenuIcon } from '@heroicons/react/outline'

function Burger({ toggle }) {
    return (
        <div
            className="right-3 flex flex-col items-end w-10 h-10 p-2 rounded-full cursor-pointer hover:bg-gray-900 hover:bg-opacity-10 text-gray-400 md:hidden"
            onClick={toggle}
        >
            <MenuIcon className='w6 h-6' />
        </div>
    )
}

Burger.propTypes = {
    toggle: PropTypes.func.isRequired,
}

export default Burger
