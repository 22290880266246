import * as React from "react"
import PropTypes from "prop-types"
import { XIcon } from '@heroicons/react/outline'

function CloseButton({ onClick }) {
    return (
        <div
            onClick={onClick}
            className="absolute right-3 z-50 flex flex-col items-end w-10 h-10 p-2 mt-4 mr-4 rounded-full cursor-pointer hover:bg-gray-900 hover:bg-opacity-10 text-gray-400"
        >
            <XIcon className='w-6 h-6' />
        </div>
    )
}

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

export default CloseButton