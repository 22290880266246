import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import CloseButton from '../closeButton'

const NAV_LINKS = [
    { label: 'Home', path: '/' },
    { label: 'Products', path: '/products' },
    { label: 'British Seasonal Info', path: '/seasonal-info' },
]

function Overlay({ toggle }) {
    return (
        <div className="fixed top-0 left-0 z-50 items-center w-full h-full p-3 text-xl bg-gray-900 bg-opacity-50">
            <div className="flex-col w-full h-full overflow-hidden bg-white rounded-lg select-none px-10">
                <CloseButton onClick={toggle} />

                <div className="flex flex-col items-center justify-center w-full h-full text-center text-indigo-700">
                    {NAV_LINKS.map(({ label, path }) => (
                        <Link key={label} to={path} className="inline-block px-4 py-2 mb-4 font-medium text-center text-primary hover:text-green-800">{label}</Link>
                    ))}
                    <span className="relative inline-flex w-full mt-3 max-w-xs">
                        <Link
                            to="/contact-us"
                            className="inline-block px-10 py-4 text-lg font-medium text-center w-full text-white bg-green-600 shadow-xl hover:bg-green-700 rounded-2xl"
                        >
                            Contact us
                        </Link>
                        <span
                            className="absolute top-0 right-0 px-4 py-2 -mt-3 -mr-6 text-xs font-medium leading-tight text-white bg-purple-500 rounded-full"
                        >
                            We are hiring!
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

Overlay.propTypes = {
    toggle: PropTypes.func.isRequired,
}

export default Overlay
